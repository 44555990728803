import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import ThumbnailTemplate, { ThumbnailDummy } from "./ThumbnailTemplate";

const Wrap = styled.div`
  position: relative;
  display: grid;
  
  grid-template-columns: repeat(${(props) => (props.myRoom ? 4 : 6)}, 264px);
  grid-column-gap: 36.5px;
  
  // grid-template-columns: repeat(auto-fill, minmax(158px, 1fr));
  // grid-template-columns: repeat(${(props) => (props.myRoom ? 4 : 5)}, 212px);
  // grid-template-columns: repeat(6, minmax(0px, 1fr));
  
  justify-content: space-between;

  height: ${(props) => (props.row ? "fit-content" : "fit-content")};

  overflow: hidden;
  width: ${(props) => (props.myRoom ? "892px" : "1768px")};
  grid-row-gap: ${(props) => (props.myRoom ? "25px" : "20px")};
  margin: 0 auto;
  margin-bottom: ${(props) => (props.row ? "0" : "40px")};
  > div {
    width: 100%;
  }
  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: repeat(2, 49%);
    grid-column-gap: 10px;
    height: fit-content;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;

export default function ThumbGridTemplate({ data, row, lastElementRef, myRoom, title }) {
  const tablet = useMediaQuery({ query: `(max-width: 768px)` });
  const [imageRatios, setImageRatios] = useState({});

  const calculateImageRatio = (imageUrl, index) => {
    const img = new Image();
    img.onload = () => {
      const ratio = img.width / img.height;
      let ratioType = 'square';
      
      if (ratio > 1.1) {
        ratioType = 'landscape';
      } else if (ratio < 0.9) {
        ratioType = 'portrait';
      }

      setImageRatios(prev => ({
        ...prev,
        [index]: ratioType
      }));
    };
    img.src = imageUrl;
  };

  useEffect(() => {
    data.forEach((content, index) => {
      if (content.coverUrl) {
        calculateImageRatio(content.coverUrl, index);
      }
    });
  }, [data]);

  return (
    <Wrap row={row} myRoom={myRoom}>
      {tablet && row
        ? data.map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailTemplate
                bgurl={content.coverUrl}
                title={content.templateName}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.temSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
                manualUuid={content.manualUuid}
                downCnt={content.downCnt}
                data={content}
                bgUrlRatio={imageRatios[i] || 'square'}
              ></ThumbnailTemplate>
            </div>
          ))
        : data.map((content, i) => (
            <div key={i} ref={data.length - 1 === i ? lastElementRef : undefined}>
              <ThumbnailTemplate
                bgurl={content.coverUrl}
                title={content.templateName}
                publisher={content.nickName}
                view={content.viewCnt}
                id={content.temSeq}
                favor={content.favorCnt}
                bookUrl={content.bookUrl}
                manualUuid={content.manualUuid}
                downCnt={content.downCnt}
                data={content}
                bgUrlRatio={imageRatios[i] || 'square'}
              ></ThumbnailTemplate>
            </div>
          ))}
    </Wrap>
  );
}
