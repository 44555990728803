import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { themeState } from "../atoms";
import down from "../images/components/Thumbnail/icon_down.svg";
import heart from "../images/components/Thumbnail/Health.svg";

import { getCookie } from "../core/cookie";

const Wrap = styled.div`
  cursor: pointer;
`;

const EbookButton = styled.span`
  color: var(--gray-white, #fff);
  text-align: center;
  font-family: Noto Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  display: flex;
  width: 55px;
  height: 21px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 2px;
  background: var(--primary-primary, #e20707);
`;

const BoxDummy = styled.div`
  position: relative;
  /* flex-basis: 198px; */
  width: 100%;
  /* height: 260px; */
  /* background: #fcfcfc; */
  /* border: 1px dashed #d6d6d6; */
  /* border-radius: 10px; */
  /* @media ${({ theme }) => theme.device.mobilelg} {
    height: 144px;
  } */
`;
const Box = styled(BoxDummy)`
  overflow: hidden;
  &:hover {
    /* border: 2px solid ${(props) => props.theme.point01}; */
    div.bgImg {
      transform: scale(1.1);
    }
  }
  div.bgWrap {
    box-shadow: rgb(0 0 0 / 7%) 0px 0px 10px 0px;
    margin-bottom: 12px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  div.bgImg {
    position: relative;
    width: 100%;
    /* aspect-ratio: ${props => {
      switch(props.bgUrlRatio) {
        case 'portrait':
          return '265/430';
        case 'landscape':
          return '430/265';
        default:
          return '1/1';
      }
    }}; */
    aspect-ratio: 265/430;
    background-image: url(${(props) => props.bgurl});
    background-position: ${props => props.bgUrlRatio === 'landscape' ? 'center center' : 'top center'};
    background-size: ${props => props.bgUrlRatio === 'landscape' ? '130%' : 'cover'};
    background-color: ${(props) => (props.bgurl == false ? "#d9d9d9" : "#000000")};
    background-repeat: no-repeat;
    transition: transform 0.3s ease 0s;
  }
  div.info {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* bottom: 0; */
    width: 100%;
    /* height: 60px; */

    /* border-radius: 0px 0px 10px 10px; */
    h3 {
      display: block;
      -webkit-box-orient: vertical;
      height: 20px;
      color: #303441;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      line-height: 20px;
      margin: 0px 0px 4px;
    }
    span {
      &.flex {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 17px;
        color: #9a9ba7;
        .publisher {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #9a9ba7;

          /* width:  */
          @media ${({ theme }) => theme.device.tablet} {
            font-size: 12px;
          }
        }
      }
      .right {
        min-width: fit-content;
        > span {
          display: inline-flex;
          align-items: center;
          gap: 3px;
        }
        img {
          margin-left: 5px;
        }
        @media ${({ theme }) => theme.device.tablet} {
          font-size: 12px;
        }
      }
    }
  }
`;

export default function ThumbnailTemplate({
  title,
  bgurl,
  publisher,
  view,
  id,
  favor,
  bookUrl,
  manualUuid,
  downCnt,
  data,
  bgUrlRatio,
}) {
  const [theme, setTheme] = useRecoilState(themeState);

  // //2023-08-29 leeho :: 썸네일 하단에 뷰어 연동 버튼 추가.
  // const onWebLink = (url) => {
  //   const cookie = getCookie("ACCESSTOKEN");
  //   const _url = cookie ? `${url}?token=${cookie}` : url;
  //   window.open(_url, "_blank");
  // };

  // const showEbook = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   onWebLink(bookUrl);
  // };


  const onClick = () => {
    const url = "view";
    window.open(
      url,
      "downloadPopup",
      "width=818, height=358,  left=50%, top=100"
    );
    
    localStorage.setItem("popupDownloadData",JSON.stringify(data));
  };

  return (
    // <Wrap onClick={onClick}>
    <Link to={`/template/${id}`}>
      <Box bgurl={bgurl} bgUrlRatio={bgUrlRatio}>
        <div className="bgWrap">
          <div className="bgImg"></div>
        </div>
        <div className="info">
          <span className="flex">
            <h3 className="pretendardR">{title}</h3>
            {/* <span className="right">
              <EbookButton onClick={showEbook}>바로보기</EbookButton>
            </span> */}
          </span>

          <span className="flex">
            <span className="publisher">
              <span className="photo"></span>
              {publisher}
            </span>
            <span className="right">
              <span className="view">
                <img src={down}></img>
                {downCnt ? downCnt : "0"}
              </span>
              {` `}
              <span className="favor">
                <img src={heart}></img>
                {favor ? favor : "0"}
              </span>              
            </span>
          </span>
        </div>
      </Box>
    </Link>
    // </Wrap>
  );
}

export function ThumbnailDummy() {
  return <BoxDummy></BoxDummy>;
}
